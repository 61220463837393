.site-header {
  min-height: 95px;
  .site-header__main {
    .site-header__section--utilities {
      top: 0;
      margin-top: 10px;
    }
    .site-header__section--branding {
      top: 25px;
    }
    .site-header__section--nav {
      top: 90px;
      overflow-x: hidden;
    }
  }
}

.main-content {
  padding-top: 95px;
}
