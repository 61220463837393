.contact-us__header,
.email-signup__header,
.contact-us-thanks-header,
.contact-us-thanks-page,
.email-signup__content,
.contact-us__content {
  max-width: 1185px;
  overflow: hidden;
  padding: 0 20px;
  input {
    &[type='email'],
    &[type='number'],
    &[type='password'],
    &[type='search'],
    &[type='tel'],
    &[type='text'],
    &[type='time'],
    &[type='url'] {
      padding: 19px 12px;
      border: 2px solid $color-lighter-gray;
      width: 100%;
    }
  }
  .form-submit {
    margin-top: 20px;
    width: 150px;
    margin-bottom: 6%;
  }
}

.email-signup__title {
  font-size: 24px;
  margin: 25px 9px 12px;
  line-height: 28px;
  letter-spacing: 2px;
  font-weight: 700;
  text-transform: uppercase;
  .email-signup__subtitle {
    margin-bottom: 20px;
  }
}

.contact-us__content {
  textarea {
    padding: 25px 12px;
    border: 2px solid $color-lighter-gray;
  }
  .form-item-form-Email-Address {
    float: none;
  }
  .form-radios {
    padding: 10px 0 20px;
  }
  .resizable-textarea {
    width: 100%;
    .grippie {
      display: none;
      width: 100%;
    }
  }
}

.site-header__main {
  .site-header__section {
    &.site-header__section--nav_promo {
      display: none;
    }
  }
}

.security-privacy,
.security-terms {
  a[name] {
    display: block;
    padding-top: 145px;
    margin-top: -145px;
  }
}

.store-locator {
  &__content {
    padding-bottom: 5%;
    .store_results {
      text-align: center;
    }
  }
}
