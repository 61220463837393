@import '../../core_base/scss/core_base_mobile';

// common css
@import 'base/generic';

.site-footer {
  .footer-links {
    padding-top: 1%;
    a {
      width: 100%;
      text-align: center;
    }
  }
  .country-chooser {
    .country_chooser__header {
      text-align: center;
      margin-top: 7%;
    }
    img,
    picture {
      max-width: 6%;
    }
  }
}

.store-locator {
  &__content {
    .city {
      width: 84%;
      @media screen and (min-width: 600px) {
        width: 91%;
      }
    }
  }
}

.mpp-container {
  .product-brief {
    &__cta--shop-now {
      .button {
        padding: 0 20px;
      }
    }
  }
}
