.product-grid--carousel {
  .slick-track {
    .product-grid__item {
      min-height: 400px;
      .product-brief__price {
        display: none;
      }
    }
  }
}

.device-mobile {
  .mpp-container {
    .product-brief {
      &__picture-container {
        width: 100%;
      }
      &__container {
        img {
          max-height: 340px;
        }
      }
      &__cta--shop-now {
        position: relative;
        .button {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}
